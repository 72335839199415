import React, { useState, useEffect } from 'react';

const decodePassthroughResponse = (response) => {
    if (response.length !== 9) {
        return 'Invalid response';  // Return a placeholder or default value
    }

    const canDataBytes = response.slice(5);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    return canData;
};

const FormComponent = ({ variables, updateMapping, maxPASLevels, regenType, onMaxPASChange, onRegenTypeChange }) => {
    const [errors, setErrors] = useState({});
    const [visibleVariables, setVisibleVariables] = useState([]);

    useEffect(() => {
        // Filter variables based on maxPASLevels
        const filteredVariables = Object.keys(variables).filter(key => {
            const variable = variables[key];
            if (variable.PAS && parseInt(variable.PAS) > maxPASLevels) {
                return false;
            }
            if (variable.BRAKE_REGEN && parseInt(variable.BRAKE_REGEN) !== regenType) {
                return false;
            }

            return true;
        });

        setVisibleVariables(filteredVariables);
    }, [variables, maxPASLevels, regenType]);

    const handleChange = (key, value) => {
        const variable = variables[key];
        const min = variable.input_value_range ? variable.input_value_range[0] : -Infinity;
        const max = variable.input_value_range ? variable.input_value_range[1] : Infinity;
        const numericValue = parseFloat(value);

        // Check for PAS levels update
        if (variable.showhide === 'PAS') {
            onMaxPASChange(parseInt(value));
        }

        // Check for regen type update
        if (variable.showhide === 'BRAKE_REGEN') {
            onRegenTypeChange(parseInt(value));
        }

        if (isNaN(numericValue) || numericValue < min || numericValue > max) {
            setErrors(prevErrors => ({ ...prevErrors, [key]: true }));
            updateMapping(key, numericValue);
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [key]: false }));
            updateMapping(key, numericValue);
        }
    };

    const renderInputField = (variable, key) => {
        switch (variable.input_type) {
            case 'text_input':
                return (
                    <div className={`input-group has-validation ${errors[key] ? 'is-invalid' : ''}`}>
                        {variable.input_unit && (
                            <span className="input-group-text">{variable.input_unit}</span>
                        )}
                        <div className="form-floating">
                            <input
                                type="text"
                                id={key}
                                className="form-control"
                                value={variable.value || ''}
                                onChange={(e) => handleChange(key, e.target.value)}
                            />
                        </div>
                    </div>
                );
            case 'decimal_input':
                return (
                    <div className={`input-group has-validation ${errors[key] ? 'is-invalid' : ''}`}>
                        {variable.input_unit && (
                            <span className="input-group-text">{variable.input_unit}</span>
                        )}
                        <div className="form-floating">
                            <input
                                type="text"
                                id={key}
                                className={`form-control ${errors[key] ? 'is-invalid' : ''}`}
                                value={((variable.value || 0) / 100).toFixed(2)}
                                onChange={(e) => handleChange(key, parseFloat(e.target.value) * 100)}
                            />
                        </div>
                    </div>
                );
            case 'combo_input':
                return (
                    <select
                        id={key}
                        className={`form-control ${errors[key] ? 'is-invalid' : ''}`}
                        value={variable.value || ''}
                        onChange={(e) => handleChange(key, e.target.value)}
                    >
                        {variable.input_value_options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.key}
                            </option>
                        ))}
                    </select>
                );
            default:
                return (
                    <div className={`input-group has-validation ${errors[key] ? 'is-invalid' : ''}`}>
                        {variable.input_unit && (
                            <span className="input-group-text">{variable.input_unit}</span>
                        )}
                        <div className="form-floating">
                            <input
                                type="text"
                                id={key}
                                className="form-control"
                                value={variable.value || ''}
                                onChange={(e) => handleChange(key, e.target.value)}
                            />
                        </div>
                    </div>
                );
        }
    };

    return (
        <form>
            <div className="row">
                <div className="col-lg-8 col-md-10">
                    <div className="row">
                        {visibleVariables.map((key, index) => {
                            const variable = variables[key];
                            const value = variable.value ? decodePassthroughResponse(variable.value) : '';
                            const options = {};
                            if (variable.input_unit) {
                                options.input_unit = variable.input_unit;
                            }

                            return (
                                <div key={index} className={`form-group col-md-5 ${variable.error ? 'error' : ''} ${variable.updated ? 'updated' : ''}`}>
                                    <label htmlFor={key}>{variable.input_title}</label>
                                    {renderInputField(variable, key)}
                                    {variable.error && (
                                        <div className="error-tooltip">
                                            <img src="/imgs/icons/icon-alt-warning.svg" alt="Error" />
                                            <span className="tooltip-text">This value did not get set in the controller.</span>
                                        </div>
                                    )}
                                    {errors[key] && (
                                        <div className="invalid-feedback">
                                            Value must be between {variable.input_value_range[0]} and {variable.input_value_range[1]}.
                                        </div>
                                    )}
                                    <small className="form-text text-muted">{variable.input_tool_tip}</small>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </form>
    );

};

export default FormComponent;
