import { useState } from 'react';
import Debug from '../services/debug';
import useBluetooth from './useBluetooth.hook';

const useAutotune = () => {
    const {
        sendPassthroughWrite,
        readPassthroughValue,
        sendPassthroughRead,
        startNotificationListener,
        stopNotificationListener,
    } = useBluetooth();

    const [autotuneProgress, setAutotuneProgress] = useState(null);
    const [autotuneResults, setAutotuneResults] = useState(null);

    const startAutotune = async (primaryService, pin, nodeId, polePairs, ratedCurrent) => {
        try {
            console.log(1, 'Starting autotune with parameters:', { polePairs, ratedCurrent });

            // Write configuration values
            await sendPassthroughRead(pin, nodeId, 0x5007, 0x01, primaryService, polePairs);
            await sendPassthroughRead(pin, nodeId, 0x5007, 0x02, primaryService, ratedCurrent);

            // Start autotune process
            await sendPassthroughRead(pin, nodeId, 0x5007, 0x00, primaryService, 1);

            // Monitor autotune progress
            await monitorAutotuneProgress(primaryService, pin, nodeId);
        } catch (error) {
            console.log(1, 'Error starting autotune:', error);
        }
    };

    const fetchAutotuneResults = async (primaryService, pin, nodeId) => {
        try {
            console.log(1, 'Fetching autotune results...');
            const results = {};

            // Define all result parameters to read
            const resultParams = [
                { index: 0x5008, subindex: 0x10, name: 'Iq_Kp' },
                { index: 0x5008, subindex: 0x11, name: 'Iq_Ki' },
                { index: 0x5008, subindex: 0x12, name: 'Id_Kp' },
                { index: 0x5008, subindex: 0x13, name: 'Id_Ki' },
                { index: 0x5008, subindex: 0x14, name: 'Stator_Resistance' },
                { index: 0x5008, subindex: 0x15, name: 'Stator_Inductance' },
            ];

            for (const param of resultParams) {
                const value = await sendPassthroughRead(pin, nodeId, param.index, param.subindex, primaryService);
                results[param.name] = value;
                console.log(1, `Fetched ${param.name}:`, value);
            }

            setAutotuneResults(results);
            console.log(1, 'Autotune results:', results);
        } catch (error) {
            console.log(1, 'Error fetching autotune results:', error);
        }
    };

    const monitorAutotuneProgress = async (primaryService, pin, nodeId) => {
        try {
            const notificationCallback = (value) => {
                const format = value[0];
                if (format === 'P'.charCodeAt(0)) {
                    const progress = value[1];
                    setAutotuneProgress(progress);
                    console.log(1, 'Autotune progress:', progress);

                    if (progress === 100) {
                        console.log(1, 'Autotune complete. Fetching results.');
                        fetchAutotuneResults(primaryService, pin, nodeId);
                    }
                }
            };

            await startNotificationListener(primaryService, notificationCallback);

            const intervalId = setInterval(async () => {
                try {
                    await sendPassthroughRead(pin, nodeId, 0x5008, 0x00, primaryService);
                    fetchAutotuneResults(primaryService, pin, nodeId);
                } catch (error) {
                    console.log(1, 'Error sending read command during progress monitoring:', error);
                }
            }, 1000);

            // Clear interval when progress is 100
            const progressObserver = setInterval(() => {
                if (autotuneProgress === 100) {
                    clearInterval(intervalId);
                    clearInterval(progressObserver);
                }
            }, 500);
        } catch (error) {
            console.log(1, 'Error monitoring autotune progress:', error);
        }
    };

    return {
        startAutotune,
        autotuneProgress,
        autotuneResults,
    };
};

export default useAutotune;
