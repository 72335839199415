import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useDiagnostics = ({
  connectToDevice,
  disconnectFromDevice,
  isConnected,
  stopNotificationListener,
  startNotificationListener,
  primaryService,
  verifyPin,
  writePassthroughCharacteristic,
  sendPassthroughRead,
  sendPassthroughWrite,
  getSendPassthroughRW,
}) => {
  const { t } = useTranslation();
  const [pin, setPin] = useState('');
  const [pinVerified, setPinVerified] = useState(false);
  const [error, setError] = useState('');
  const [isPrimaryServiceAvailable, setIsPrimaryServiceAvailable] = useState(false);
  const [results, setResults] = useState([]);
  const [errors, setErrors] = useState([]);
  const [batteryStatusMessage, setBatteryStatusMessage] = useState('');
  const [brakeState, setBrakeState] = useState(null);
  const [frontLightState, setFrontLightState] = useState(null);
  const [rearLightState, setRearLightState] = useState(null);
  const [readWritePassthrough, setReadWritePassthrough] = useState(null);

  const gattQueueRef = useRef([]);
  const gattOperationInProgressRef = useRef(false);
  const intervalsRef = useRef([]);

  const errorDescriptions = {
    0x00000000: t("No error"),
    0x00000001: t("Throttle stuck"),
    0x00000002: t("Under voltage"),
    0x00000004: t("Over voltage"),
    0x00000008: t("Under temperature"),
    0x00000010: t("Motor Over temperature"),
    0x00000020: t("Motor foldback temperature"),
    0x00000040: t("Motor NTC Disconnected/ low temperature"),
    0x00000080: t("Controller over temperature"),
    0x00000100: t("Controller foldback temperature"),
    0x00000200: t("Motor Hall error"),
    0x00000400: t("Motor Phase Error"),
    0x00000800: t("IoT Comm Error"),
    0x00001000: t("Dual Comm Error"),
    0x00002000: t("Over Current"),
    0x00004000: t("Battery Low"),
    0x00008000: t("PAS Sensor Error"),
    0x00010000: t("Controller Error"),
    0x00020000: t("Brake Error"),
    0x00040000: t("Screen Communication Error"),
    0x00080000: t("Torque Sensor Stuck Error"),
    0x00200000: t("Battery Error"),
    0x00400000: t("Powertrain Locked")
  };

  const cloudDriveErrorMapping = {
    1: '7', // Throttle stuck
    2: '4', // Under voltage
    4: '12', // Over voltage
    8: '8', // Under temperature
    16: '11', // Motor Over temperature
    32: '11', // Motor foldback temperature
    64: '14', // Motor NTC Disconnected/ low temperature
    128: '9', // Controller over temperature
    256: '9', // Controller foldback temperature
    512: '6', // Motor Hall error
    1024: '3', // Motor Phase Error
    2048: '10', // IoT Comm Error
    16384: '13', // Battery Low
    32768: '1', // PAS Sensor Error
    65536: '2', // Controller Anomaly
    131072: '5'  // Brake Error
  };

  const commands = [
    { interval: 0, category: 'Screen', subName: 'Max vehicle speed', index: 0x201C, subIndex: 0x00, description: 'Max speed of the vehicle. Overrides PAS speeds or throttle speeds if its value is lower', unit: 'km/h' },
    { interval: 0, category: 'Screen', subName: 'Walk mode speed', index: 0x201D, subIndex: 0x00, description: 'Speed that the walk mode of the vehicle goes up to', unit: 'km/h' },
    { interval: 0, category: 'Screen', subName: 'Wheel diameter', index: 0x201F, subIndex: 0x00, description: 'Diameter of the vehicle\'s wheels. This is the wheel where the wheel speed sensor is installed.', unit: 'inches' },
    { interval: 0, category: 'Screen', subName: 'Pulses per rotation', index: 0x201F, subIndex: 0x01, description: 'Number of pulses per rotation of the wheel speed sensor', unit: '' },
    { interval: 0, category: 'Screen', subName: 'Current throttle ADC value', index: 0x2027, subIndex: 0x00, description: 'Current ADC value of the throttle (if using an analog throttle). The value is the raw ADC value scaled between 0-65535 (0 means 0V, 65535 means 5V)', unit: '' },
    { interval: 0, category: 'Screen', subName: 'Throttle value', index: 0x2027, subIndex: 0x01, description: 'Get or set the value of the throttle, in % x 100. For example, setting this to: - 3476 means, 34.76% of throttle - 9995 means 99.95% of throttle - 10000 means 100.00% of throttle (ie. max throttle) In the case of missing heartbeats from the HMI (see communication specification tab), the controller will disable the throttle. Setting the throttle is meant to be used in - diagnostics tool scenarios - screens that have the throttle connected into them and that are passing along the throttle value to the controller.', unit: '%' },
    { interval: 0, category: 'Screen', subName: 'Throttle min value', index: 0x2027, subIndex: 0x02, description: 'Analog throttle min value (ie. offset), between 0-65535 (0 means 0V, 65535 means 5V). The minimum value is 10 500, which corresponds to approximately 0.8V, for safety reasons.', unit: '' },
    { interval: 0, category: 'Screen', subName: 'Throttle max value', index: 0x2027, subIndex: 0x03, description: 'Analog throttle max value, between 0-65535 (0 means 0V, 65535 means 5V). The minimum safe value is 10 500, which corresponds to approximately 0.8V, for safety reasons.', unit: '' },
    { interval: 0, category: 'Screen', subName: 'Throttle enabled state', index: 0x2027, subIndex: 0x04, description: 'Get/set if the throttle is enabled or not. 0 = throttle disabled 1 = throttle enabled', unit: '' },
    { interval: 0, category: 'Screen', subName: 'Throttle max speed', index: 0x2027, subIndex: 0x05, description: 'Get/set the max speed that the vehicle should reach on throttle', unit: '' },
    { interval: 0, category: 'Screen', subName: 'Screen protocol selection', index: 0x2025, subIndex: 0x00, description: 'Select the protocol to use over the UART channel. The FTEX controller can communicate with screens over UART, as well as over CAN - both can work in parallel. Set the values to the following to control which protocol the FTEX controller uses: 0 = No UART protocol (ie. CAN screen can be used) 1 = UART FTEX/APT protocol 2 = UART FTEX/Clouddrive protocol', unit: '' },
    { interval: 20, category: 'Battery', subName: 'Battery SOC', index: 0x2002, subIndex: 0x00, description: 'Current approximate SOC of the battery in %', unit: '%' },
    { interval: 20, category: 'Battery', subName: 'Master bus voltage', index: 0x200A, subIndex: 0x00, description: 'Current bus voltage on the controller (master)', unit: 'centivolts' },
    { interval: 0, category: 'Battery', subName: 'Battery full voltage', index: 0x201E, subIndex: 0x00, description: 'Battery full voltage. Needs to be bigger than battery empty voltage', unit: 'centivolts' },
    { interval: 0, category: 'Battery', subName: 'Battery empty voltage', index: 0x201E, subIndex: 0x01, description: 'Battery empty voltage. Needs to be smaller than battery full voltage', unit: 'centivolts' },
    { interval: 0, category: 'Battery', subName: 'Max DC current', index: 0x2026, subIndex: 0x00, description: 'Max DC current that the controller can draw from the battery', unit: 'amps' },
    { interval: 0, category: 'Battery', subName: 'Continuous DC current', index: 0x2026, subIndex: 0x01, description: 'Continuous DC current that the controller can draw from the battery', unit: 'amps' },
    { interval: 0, category: 'Battery', subName: 'Peak current duration', index: 0x2026, subIndex: 0x02, description: 'Duration of the peak current, after which the controller starts derating to continuous current', unit: 'milliseconds' },
    { interval: 10, category: 'Diagnostics and configuration', subName: 'Motor temperature', index: 0x200B, subIndex: 0x00, description: 'Temperature of the motor connected to the controller (if available)', unit: 'Celsius' },
    { interval: 0, category: 'Diagnostics and configuration', subName: 'Max motor temperature', index: 0x200B, subIndex: 0x02, description: 'Max temperature that the motor can reach before being shut down', unit: 'Celsius' },
    { interval: 10, category: 'Diagnostics and configuration', subName: 'Controller temperature', index: 0x200C, subIndex: 0x00, description: 'Current temperature of the controller (master)', unit: 'Celsius' },
    { interval: 0, category: 'Diagnostics and configuration', subName: 'Max controller temperature', index: 0x200C, subIndex: 0x02, description: 'Maximum temperature that the controller can reach before it shuts down. If a value is set to higher than the controller\'s internal max safe temperature, it will be ignored', unit: '' },
    // { interval: 30, category: 'Health', subName: 'BMS errors', index: 0x20, subIndex: 0x0, description: 'Errors currently detected by the BMS system. If more than one error is occuring, the BMS does an OR mask between errors, to flag different error bits. 0 = no error 0x1 = Overtemperature error 0x2 = Undertemperature error 0x4 = Cell imbalance warning 0x8 = Overvoltage error 0x10 = Undervoltage error 0x20 = Charge overcurrent error 0x40 = Discharge overcurrent error 0x80 = Shortcircuit error 0x80000000 = Internal error (ie. unmapped errors)', unit: '' },
    // { interval: 30, category: 'Health', subName: 'Battery temperature', index: 0x20, subIndex: 0x1, description: 'Temperature of the battery', unit: 'Celsius' },
    // { interval: 30, category: 'Battery real-time information', subName: 'Battery SOC', index: 0x30, subIndex: 0x0, description: 'State-of-charge of the battery, between 0% and 100%', unit: '%' },
    // { interval: 30, category: 'Battery real-time information', subName: 'Battery voltage', index: 0x30, subIndex: 0x1, description: 'Voltage of the battery (within one hundredth of a volt)', unit: 'centivolts' },
    // { interval: 30, category: 'Battery real-time information', subName: 'Battery current', index: 0x30, subIndex: 0x2, description: 'Real-time charge or discharge current of the battery. Positive current means discharge of the battery. Negative current means charge of the battery', unit: 'milliamps' },
    // { interval: 30, category: 'Battery real-time information', subName: 'Battery state', index: 0x30, subIndex: 0x3, description: 'State of the battery. 0 = undefined (should not happen) 1 = Charging 2 = Discharging 3 = Fully charged 4 = Fully discharged 5 = Error (and the error state 0x20 is set)', unit: '' },
    // { interval: 0, category: 'Metadata', subName: 'Battery serial number MSB', index: 0x40, subIndex: 0x0, description: 'Serial number of the battery (most significant bytes)', unit: '' },
    // { interval: 0, category: 'Metadata', subName: 'Battery serial number LSB', index: 0x40, subIndex: 0x1, description: 'Serial number of the battery (least significant bytes)', unit: '' },
    // { interval: 0, category: 'Metadata', subName: 'Cycle count', index: 0x40, subIndex: 0x2, description: 'Number of times the battery went through a discharge cycle (a discharge is if SOC goes below 95%)', unit: '' },
    // { interval: 0, category: 'Metadata', subName: 'Battery model number', index: 0x40, subIndex: 0x3, description: 'Number defined by the battery manufacturer identifying this battery model.', unit: '' },
    // { interval: 0, category: 'Battery capabilities', subName: 'Max discharge current', index: 0x50, subIndex: 0x0, description: 'Maximum current that the BMS can sustain while discharging', unit: 'milliamps' },
    // { interval: 0, category: 'Battery capabilities', subName: 'Max charge current', index: 0x50, subIndex: 0x1, description: 'Maximum current that the BMS can sustain while charging', unit: 'milliamps' },
    // { interval: 0, category: 'Battery capabilities', subName: 'Max discharge time', index: 0x50, subIndex: 0x2, description: 'Time during which the BMS can sustain the max discharge current', unit: 'milliseconds' },
    // { interval: 0, category: 'Battery capabilities', subName: 'Continuous current', index: 0x50, subIndex: 0x3, description: 'Continuous current that the BMS can sustain', unit: 'milliamps' },
    // { interval: 0, category: 'Battery capabilities', subName: 'Max voltage', index: 0x50, subIndex: 0x4, description: 'Max voltage of the battery (ie. voltage corresponding to 100% SOC)', unit: 'centivolts' },
    // { interval: 0, category: 'Battery capabilities', subName: 'Min voltage', index: 0x50, subIndex: 0x5, description: 'Min voltage of the battery (ie. voltage corresponding to 0% SOC)', unit: 'centivolts' },
    { interval: 1, category: 'Live', subName: 'Vehicle speed with decimals', index: 0x2000, subIndex: 0x01, description: 'Speed of the vehicle, expressed as a 3-digits number for speed with 1 decimals. For example: - if this parameter returns 234, the speed is 23.4 km/h - if this parameter returns 1, the speed is 0.1 km/h - if this parameter return 34, the speed is 3.4 km/h - if this parameter is 999, the speed is 99.9 km/h', unit: 'km/h x 10 (ie. hectometer/s)' },
    { interval: 1, category: 'Live', subName: 'Real-time total power', index: 0x2001, subIndex: 0x00, description: 'Real-time approximate total power of the vehicle (ie. DC Power). Note: screens/IoT should use this value if they want to display a power value, because this value is more relevant to the user.', unit: 'watts' },
    { interval: 1, category: 'Live', subName: 'Requested motor torque', index: 0x2001, subIndex: 0x01, description: 'Real-time approximate requested torque on the motor. Note: this is a torque setpoint, it may differ from the actual motor mechanical torque.', unit: 'cNm' },
    { interval: 1, category: 'Live', subName: 'Mechanical power in motor', index: 0x2001, subIndex: 0x02, description: 'Real-time approximate mechanical power in the motor (ie. torque x speed).', unit: 'watts' },
    { interval: 1, category: 'Live', subName: 'Active errors', index: 0x2006, subIndex: 0x00, description: 'Get the active errors currently on the controller. 0 means no error. see the FTEX Errors Definition tab', unit: '' },
    { interval: 10, category: 'Live', subName: 'Motor temperature', index: 0x200B, subIndex: 0x00, description: 'Temperature of the motor connected to the controller (if available)', unit: 'Celsius' },
    { interval: 10, category: 'Live', subName: 'Controller temperature', index: 0x200C, subIndex: 0x00, description: 'Current temperature of the controller (master)', unit: 'Celsius' },
    // { interval: 1, category: 'Live', subName: 'Current throttle ADC value', index: 0x2027, subIndex: 0x00, description: 'Current ADC value of the throttle (if using an analog throttle). The value is the raw ADC value scaled between 0-65535 (0 means 0V, 65535 means 5V)', unit: '' },
    { interval: 1, category: 'Live', subName: 'Throttle value', index: 0x2027, subIndex: 0x01, description: 'Get or set the value of the throttle, in % x 100. For example, setting this to: - 3476 means, 34.76% of throttle - 9995 means 99.95% of throttle - 10000 means 100.00% of throttle (ie. max throttle) In the case of missing heartbeats from the HMI (see communication specification tab), the controller will disable the throttle. Setting the throttle is meant to be used in - diagnostics tool scenarios - screens that have the throttle connected into them and that are passing along the throttle value to the controller.', unit: '%' },
    { interval: 1, category: 'Live', subName: 'Pedaling cadence', index: 0x2024, subIndex: 0x00, description: 'Current pedaling cadence from the PAS sensor, in RPM. Positive means, pedaling forward. Negative means, pedaling backwards. Only forward pedaling is supported for now.', unit: 'rpm' },
    { interval: 1, category: 'Live', subName: 'Torque detected on pedals', index: 0x2024, subIndex: 0x01, description: 'Current torque detected on the pedals (if torque sensor present)', unit: '%' },
    { interval: 1, category: 'Live', subName: 'Brake state', index: 0x202C, subIndex: 0x00, description: 'The current brake state. 0 = not pressed. 1 = brake pressed. For the time being, this parameter assumes that the brake is not an analog brake.', unit: 'uint8_t' },
    { interval: 1, category: 'Live', subName: 'PAS levels', index: 0x2003, subIndex: 0x00, description: 'Get or set the current PAS level of the vehicle. Note: setting to PAS level 15 activates walk mode.', unit: 'uint8_t' },
    { interval: 1, category: 'Live', subName: 'Front light state', index: 0x2021, subIndex: 0x00, description: 'Manually get or set the front light state. 0 means off, 1 means on.', unit: 'uint8_t' },
    { interval: 1, category: 'Live', subName: 'Rear light state', index: 0x2022, subIndex: 0x00, description: 'Manually get or set the rear light state. 0 means off, 1 means on.', unit: 'uint8_t' },
  ];

  const batteryCommands = commands.filter(command => command.category === 'Battery' || command.category.includes('Battery'));
  const liveCommands = commands.filter(command => command.category === 'Live' || command.category.includes('Live'));
  const otherCommands = commands.filter(command => !batteryCommands.includes(command) && !liveCommands.includes(command));

  useEffect(() => {
    if (primaryService) {
      setIsPrimaryServiceAvailable(true);
    } else {
      setIsPrimaryServiceAvailable(false);
    }

    if (primaryService && pinVerified) {
      getSendPassthroughRW(primaryService).then(sendFunction => {
        if (sendFunction) {
          setReadWritePassthrough(() => sendFunction);
        } else {
          console.error('Failed to initialize passthrough function');
        }
      });
    }
  }, [primaryService, pinVerified, pin]);

  useEffect(() => {
    if (typeof readWritePassthrough === "function") {
      runDiagnosticsCommands(commands);
      runNextGattOperation();
      startIntervals();
    }
  }, [readWritePassthrough]);

  useEffect(() => {
    const masterBusVoltageResult = results.find(result => result.index === 0x200A && result.subIndex === 0x00);
    const batteryFullVoltageResult = results.find(result => result.index === 0x201E && result.subIndex === 0x00);
    const batteryEmptyVoltageResult = results.find(result => result.index === 0x201E && result.subIndex === 0x01);

    if (masterBusVoltageResult && batteryFullVoltageResult && batteryEmptyVoltageResult) {
      const masterBusVoltage = masterBusVoltageResult.latestValue;
      const batteryFullVoltage = batteryFullVoltageResult.latestValue;
      const batteryEmptyVoltage = batteryEmptyVoltageResult.latestValue;

      if (masterBusVoltage > batteryFullVoltage || masterBusVoltage < batteryEmptyVoltage) {
        setBatteryStatusMessage(t('There is a battery problem.'));
      } else {
        setBatteryStatusMessage(t('Battery diagnostics has passed.'));
      }
    }
  }, [results, t]);

  const decodePassthroughResponse = (response) => {
    if (response.length < 9) {
      console.error('Invalid response length:', response.length);
      return null;
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const index = (canHeader[2] << 8) | canHeader[1];
    const subIndex = canHeader[3];

    return { index, subIndex, canData };
  };

  const decodeErrorResponse = (value) => {
    const errorValue = new DataView(new ArrayBuffer(8));
    new Uint8Array(errorValue.buffer).set(new Uint8Array(value.slice(1, 9)));

    const errorValueString = JSON.stringify(Array.from(new Uint8Array(errorValue.buffer)));
    console.log('error detected: ' + errorValueString);

    return readErrorCodeCharacteristic(errorValue);
  };

  const readErrorCodeCharacteristic = (dataView) => {
    if (dataView.byteLength !== 8) {
      throw new Error("Invalid data length for error code characteristic");
    }
    const errorCode = dataView.getUint32(0, true);
    const timestamp = dataView.getUint32(4, true);
    const binaryString = errorCode.toString(2).padStart(32, '0');
    console.log('error code char', errorCode, binaryString, dataView);

    const currentErrors = [];
    Object.keys(errorDescriptions).forEach((key, index) => {
      if (binaryString[31 - index] === '1') {
        currentErrors.push({
          code: cloudDriveErrorMapping[key],
          description: errorDescriptions[key],
          timestamp: new Date(timestamp * 1000).toISOString(),
        });
      }
    });
    return currentErrors.length > 0 ? currentErrors : [{
      code: '0',
      description: 'No error',
      timestamp: new Date(timestamp * 1000).toISOString(),
    }];
  };

  const notificationCallback = (event) => {
    if (event) {
      const value = new Uint8Array(event.buffer);
      console.log('USEDIAGNOSTICS NOTIFICATION');
      const messageType = String.fromCharCode(value[0]);

      if (messageType === 'E') {
        const decodedErrors = decodeErrorResponse(value);
        setErrors(decodedErrors);
      } else if (messageType === 'P') {
        const { index, subIndex, canData } = decodePassthroughResponse(value);
        if (canData !== null) {
          setResults((prevResults) => {
            const existingResultIndex = prevResults.findIndex(result => result.index === index && result.subIndex === subIndex);
            const currentTime = new Date().toISOString();

            if (existingResultIndex !== -1) {
              const updatedResults = [...prevResults];
              updatedResults[existingResultIndex] = {
                ...updatedResults[existingResultIndex],
                latestValue: canData,
                time: currentTime,
              };
              return updatedResults;
            } else {
              const command = commands.find(cmd => cmd.index === index && cmd.subIndex === subIndex);
              if (command) {
                const newResults = [
                  ...prevResults,
                  {
                    category: command.category,
                    name: command.name,
                    subName: command.subName,
                    description: command.description,
                    unit: command.unit,
                    index,
                    subIndex,
                    latestValue: canData,
                    time: currentTime,
                  },
                ];
                return newResults;
              }
              return prevResults;
            }
          });

          if (index === 0x2021 && subIndex === 0) {
            setFrontLightState(canData === 1 ? 'Front Light ON' : 'Front Light OFF');
          }
          if (index === 0x2022 && subIndex === 0) {
            setRearLightState(canData === 1 ? 'Rear Light ON' : 'Rear Light OFF');
          }
          if (index === 0x202C && subIndex === 0) {
            setBrakeState(canData === 1);
          }
        } else {
          console.error('Failed to decode response:', value);
        }
      } else {
        console.error('Unexpected format or type in notification value:', value);
      }
    } else {
      console.error('Invalid event structure:', event);
    }
  };

  const enqueueGattOperation = async (args = []) => {
    const operationKey = `function-${JSON.stringify(args)}`;
    const isDuplicate = gattQueueRef.current.some(op => op.key === operationKey);

    if (args[0] === 'write') {
      gattQueueRef.current.push({ args, key: operationKey });
    } else if (!isDuplicate) {
      gattQueueRef.current.push({ args, key: operationKey });
    }
  };

  const runNextGattOperation = async () => {
    if (primaryService && pinVerified && readWritePassthrough) {
      if (!gattOperationInProgressRef.current && gattQueueRef.current.length > 0) {
        const nextOperationIndex = gattQueueRef.current.findIndex(op => op.args[0] === 'write');
        const operation = nextOperationIndex !== -1 ? gattQueueRef.current.splice(nextOperationIndex, 1)[0] : gattQueueRef.current.shift();

        try {
          gattOperationInProgressRef.current = true;
          await readWritePassthrough(...operation.args);
        } catch (error) {
          console.error('Error running GATT operation:', error);
        } finally {
          gattOperationInProgressRef.current = false;
          runNextGattOperation(); // Continue with the next operation
        }
      } else {
        setTimeout(runNextGattOperation, 50); // Wait 50ms before trying again
      }
    }
  };

  const startIntervals = () => {
    stopIntervals();
    const intervalsMap = {};

    if (intervalsRef.current) {
      intervalsRef.current.forEach(clearInterval);
      intervalsRef.current = [];
    }

    commands.forEach((command) => {
      if (command.interval > 0) {
        if (!intervalsMap[command.interval]) {
          intervalsMap[command.interval] = [];
        }
        intervalsMap[command.interval].push(command);
      } else {
        // Run commands with interval 0 once at the start
        enqueueGattOperation(['read', pin, 0x00000601, command.index, command.subIndex]);
      }
    });

    intervalsRef.current = Object.keys(intervalsMap).map(interval => {
      const commandList = intervalsMap[interval];
      if (commandList.length > 0) {
        return setInterval(() => {
          runDiagnosticsCommands(commandList);
        }, interval * 250); // Ensure interval is in milliseconds
      }
      return null;
    }).filter(interval => interval !== null);
  };

  const runDiagnosticsCommands = async (commandList) => {
    if (!Array.isArray(commandList)) {
      commandList = commands;
    }
    for (const command of commandList) {
      try {
        enqueueGattOperation(['read', pin, 0x00000601, command.index, command.subIndex]);
      } catch (error) {
        console.error('Error sending command:', command, error);
      }
    }
  };

  useEffect(() => {
    return () => {
      stopIntervals();
    };
  }, []);

  const stopIntervals = () => {
    if (intervalsRef.current) {
      intervalsRef.current.forEach(clearInterval);
      intervalsRef.current = [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = await verifyPin(pin, primaryService);
      if (isValid) {
        await stopNotificationListener(primaryService);
        await startNotificationListener(primaryService, notificationCallback);
        setError('');
        setPinVerified(true);
        return true;
      } else {
        setError(t('Invalid PIN'));
        return false;
      }
    } catch (err) {
      setError(t('Error verifying PIN'));
      return false;
    }
  };

  const handleSubmitOnly = async (e) => {
    e.preventDefault();
    try {
      const isValid = await verifyPin(pin, primaryService);
      if (isValid) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const runDiagnosticsCommandsAfterWait = async () => {
    try {
      await runDiagnosticsCommands();
    } catch (err) {
      setError(t('Error verifying PIN'));
    }
  };

  const handleFrontLightNotification = (event) => {
    const value = new Uint8Array(event.buffer);
    setFrontLightState(value[0] === 1 ? 'Front Light ON' : 'Front Light OFF');
  };

  const handleRearLightNotification = (event) => {
    const value = new Uint8Array(event.buffer);
    setRearLightState(value[0] === 1 ? 'Rear Light ON' : 'Rear Light OFF');
  };

  const toggleFrontLight = async () => {
    const newState = frontLightState === 'Front Light ON' ? 0 : 1;
    enqueueGattOperation(['write', pin, 0x00000601, 0x2021, 0x00, newState]);
  };

  const toggleRearLight = async () => {
    const newState = rearLightState === 'Rear Light ON' ? 0 : 1;
    enqueueGattOperation(['write', pin, 0x00000601, 0x2022, 0x00, newState]);
  };

  const stopUpdates = () => {
    console.log('stopUpdates', intervalsRef.current); 
    gattOperationInProgressRef.current = true;
    if (intervalsRef.current) {
      intervalsRef.current.forEach(clearInterval);
      intervalsRef.current = [];
    }

    gattQueueRef.current = [];
  };

  const restartUpdates = async () => {
    gattOperationInProgressRef.current = false;
    await stopNotificationListener(primaryService);
    await startNotificationListener(primaryService, notificationCallback);

    startIntervals();
    runNextGattOperation();
  };

  const restartUpdatesOnly = async () => {
    gattOperationInProgressRef.current = false;
    startIntervals();
    runNextGattOperation();
  };

  return {
    t,
    pin,
    setPin,
    error,
    setError,
    isPrimaryServiceAvailable,
    connectToDevice,
    disconnectFromDevice,
    isConnected,
    pinVerified,
    setPinVerified,
    results,
    batteryCommands,
    liveCommands,
    otherCommands,
    batteryStatusMessage,
    errors,
    handleSubmit,
    handleSubmitOnly,
    runDiagnosticsCommands,
    brakeState,
    frontLightState,
    rearLightState,
    toggleFrontLight,
    toggleRearLight,
    getSendPassthroughRW,
    primaryService,
    stopUpdates,
    restartUpdates,
    restartUpdatesOnly,
    stopNotificationListener,
    startNotificationListener,
  };
};

export default useDiagnostics;