import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../logo.component';
import ReactCodeInput from 'react-code-input';

const decodePassthroughResponse = (response) => {
    if (response.length < 9) {
      console.error('Invalid response length:', response.length);
      return null;
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const index = (canHeader[2] << 8) | canHeader[1];
    const subIndex = canHeader[3];

    return { index, subIndex, canData };
};

const PinEntryComponent = ({ 
    userGroup,
    bikeSerial,
    primaryService,
    verifyPin,
    writePassthroughCharacteristic,
    connectToDevice,
    isConnected,
    disconnectFromDevice,
    readPassthroughValue,
    handleDataLoaded,
    updateMapping,
    dataLoaded,
    performFirmwareUpdate,
    startNotificationListener,
    stopNotificationListener,
    getSendPassthroughRW,
    mapping,
    setMapping,
    pin,
    setPin,
    reloadMapping,
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    // const [valuesRetrieved, setValuesRetrieved] = useState(0);
    const [totalValues, setTotalValues] = useState(0);

    const gattQueueRef = useRef([]);
    const gattOperationInProgressRef = useRef(false);

    let valuesRetrieved = 0;
    let killNextGatt = false;
    let readWritePassthrough = null;
    let retries = 0;

    const handlePinChange = (value) => {
        setPin(value);
    };

    useEffect(() => {
        let total = 0;
        Object.entries(mapping).forEach(([categoryKey, category]) => {
            Object.entries(category).forEach(([subCategoryKey, subCategory]) => {
                total += Object.entries(subCategory.variables).length;
            });
        });
        setTotalValues(total);
    }, [mapping]);

    useEffect(() => {
        if (!isConnected && !dataLoaded) {
            navigate('/configuration');
        }
    }, [isConnected, dataLoaded, navigate]);

    const handleDisconnection = () => {
        setShowModal(true);
        disconnectFromDevice();
        reloadMapping();
    };

    const handleConnect = async () => {
        reloadMapping();
        valuesRetrieved = 0;
        killNextGatt = false;
        readWritePassthrough = null;
        retries = 0;

        setShowModal(false);
        setLoading(false);
        setProgress(0);


        const serial = await connectToDevice();
        if (serial) {
            navigate('/configuration/pin-entry');
        }
    };

    const stopReadingValues = () => {
        let missingValues = 0;
        for (const [categoryKey, category] of Object.entries(mapping)) {
            for (const [subCategoryKey, subCategory] of Object.entries(category)) {
                for (const [variableKey, variableDetails] of Object.entries(subCategory.variables)) {
                    const { input_title, index, sub_index, value } = variableDetails;
                    if (value === undefined) {
                        missingValues++;
                        console.log('enqueue');
                        enqueueGattOperation(['read', pin, 0x00000601, parseInt(index, 16), parseInt(sub_index, 16)]);
                    }
                }
            }
        }

        retries++;
        console.log('missingValues', missingValues, 'retries', retries, missingValues === 0, retries > 2);
        if (missingValues === 0 || retries > 1) {
            console.log('killNextGatt');
            stopNotificationListener(primaryService);
            killNextGatt = true;
            setLoading(false);
            handleDataLoaded();
            navigate(`/configuration/${menuItems[0].toLowerCase()}`);       
        } else {
            runNextGattOperation();
        }
    }

    const countVariablesWithValues = (data) => {
        let count = 0;

        const checkValues = (obj) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    
                    if (typeof value === 'object' && value !== null) {
                        if (value.hasOwnProperty('value')) {
                            count++;
                        }
                        checkValues(value);
                    }
                }
            }
        };

        checkValues(data);
        return count;
    };

    const notificationCallback = (event) => {
        const value = new Uint8Array(event.buffer);
        const decodedValue = decodePassthroughResponse(value);
        if (decodedValue !== null) {
            // Find the corresponding entry in the mapping
            const { index, subIndex, canData } = decodePassthroughResponse(value);
            console.log('notificationCallback', decodedValue);

            setMapping(prevMapping => {
                const newMapping = { ...prevMapping };
                Object.entries(newMapping).forEach(([categoryKey, category]) => {
                    Object.entries(category).forEach(([subCategoryKey, subCategory]) => {
                        Object.entries(subCategory.variables).forEach(([variableKey, variableDetails]) => {
                            if (parseInt(variableDetails.index, 16) === index && parseInt(variableDetails.sub_index, 16) === subIndex) {
                                newMapping[categoryKey][subCategoryKey].variables[variableKey].value = canData;
                                //newMapping[categoryKey][subCategoryKey].variables[variableKey].updated = true;
                            }
                        });
                    });
                });
                return newMapping;
            });

            valuesRetrieved = countVariablesWithValues(mapping);


            setProgress(Math.round((valuesRetrieved / totalValues) * 100));
            console.log('notificationCallback valuesRetrieved', valuesRetrieved, totalValues);
            if (valuesRetrieved > totalValues) {
                setProgress(100);
                stopReadingValues();
            }
        }
    }

    const enqueueGattOperation = async (args = []) => {
        const operationKey = `function-${JSON.stringify(args)}`;
        const isDuplicate = gattQueueRef.current.some(op => op.key === operationKey);

        if (args[0] === 'write') {
            gattQueueRef.current.push({ args, key: operationKey });
        } else if (!isDuplicate) {
            console.log('enqueue', args);
            gattQueueRef.current.push({ args, key: operationKey });
        } else {
            console.log('enqueue DUPLICATE', args, gattQueueRef.current);            
        }
    };

    const runNextGattOperation = async () => {
        console.log('running runNextGattOperation');
        if (killNextGatt) {
            // console.log('runNextGattOperation kill');
            return;
        }
        // console.log('runNextGattOperation', readWritePassthrough);
        if (!readWritePassthrough && primaryService) {
            await startNotificationListener(primaryService, notificationCallback);
            // console.log('get readWritePassthrough')
            await getSendPassthroughRW(primaryService).then(returnedFunction => {
                if (returnedFunction) {
                    readWritePassthrough = returnedFunction;
                } else {
                    console.error('Failed to initialize passthrough function');
                }
            });            
        }

        if (primaryService && readWritePassthrough) {
            // console.log('get readWritePassthrough 1', gattQueueRef.current);
            if (!gattOperationInProgressRef.current && gattQueueRef.current.length > 0) {
                // console.log('get readWritePassthrough 2')
                const nextOperationIndex = gattQueueRef.current.findIndex(op => op.args[0] === 'write');
                const operation = nextOperationIndex !== -1 ? gattQueueRef.current.splice(nextOperationIndex, 1)[0] : gattQueueRef.current.shift();
                // console.log('get readWritePassthrough 3', operation);

                try {
                    gattOperationInProgressRef.current = true;
                    await readWritePassthrough(...operation.args);
                } catch (error) {
                    console.error('Error running GATT operation:', error);
                } finally {
                    gattOperationInProgressRef.current = false;
                    runNextGattOperation(); // Continue with the next operation
                }
            } else {
                //setTimeout(runNextGattOperation, 50); // Wait 50ms before trying again
                setProgress(99);
                stopReadingValues();
            }
        } else {
            setTimeout(runNextGattOperation, 50); // Wait 50ms before trying again
        }
    };

    const readAndLogMappingValues = async (pin, mapping, primaryService) => {
        let totalValues = 0;

        // Calculate total values to retrieve
        Object.entries(mapping).forEach(([categoryKey, category]) => {
            Object.entries(category).forEach(([subCategoryKey, subCategory]) => {
                totalValues += Object.entries(subCategory.variables).length;
            });
        });

        for (const [categoryKey, category] of Object.entries(mapping)) {
            for (const [subCategoryKey, subCategory] of Object.entries(category)) {
                for (const [variableKey, variableDetails] of Object.entries(subCategory.variables)) {
                    const { index, sub_index } = variableDetails;
                    enqueueGattOperation(['read', pin, 0x00000601, parseInt(index, 16), parseInt(sub_index, 16)]);
                }
            }
        }
        runNextGattOperation();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        reloadMapping();
        valuesRetrieved = 0;
        killNextGatt = false;
        readWritePassthrough = null;
        retries = 0;

        setShowModal(false);
        setLoading(false);
        setProgress(0);



        setLoading(true);
        setProgress(0);
        try {
            const isValid = await verifyPin(pin, primaryService);
            if (isValid) {
                await readAndLogMappingValues(pin, mapping, primaryService);
            } else {
                setError('Invalid PIN. Please try again.');
                handleDisconnection();
            }
        } catch (err) {
            setError('Error verifying PIN. Please try again.');
            handleDisconnection();
        }
    };

    useEffect(() => {
        setMenuItems(Object.keys(mapping));
    }, [mapping]);

    return (
        <>
            <div className="col-md-12 connection pin-verification text-center">
                <Logo userGroup={userGroup} />
                {!isConnected ? (
                    <>
                        <p>
                            {t('For a successful connection, please ')}
                            <span>
                                {t('turn on Bluetooth on both devices.')}
                            </span>
                        </p>
                        <div className="text-center">
                            <button onClick={handleConnect} className="btn btn-primary btn-large" disabled={loading}>
                                {loading ? 'Connecting...' : 'Connect to Bike'}
                            </button>
                        </div>
                    </>
                ) : !primaryService ? (
                    <div className="text-center">
                        <p>Loading primary service...</p>
                    </div>
                ) : (
                    <>
                        <div className="row text-center d-flex align-items-center justify-content-center h-100">
                            <div className="col-2 text-left">
                                <div className="pin-entry config-tool">
                                    <h4>{t("PIN Required")}</h4>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <ReactCodeInput
                                            type="number"
                                            fields={4}
                                            id="pin"
                                            value={pin}
                                            onChange={handlePinChange}
                                            />
                                            <label htmlFor="pin">{t('For a successful connection, please turn on Bluetooth on both devices.')}</label>
                                        </div>
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <button type="submit" className="btn btn-block btn-primary">{t('Connect')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Modal show={loading} centered>
                <Modal.Header>
                    <Modal.Title>Loading... {progress}%</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar now={progress} />
                </Modal.Body>
            </Modal>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Incorrect PIN</Modal.Title>
                </Modal.Header>
                <Modal.Body>Incorrect PIN. Please try again.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => navigate('/')}>
                        Reconnect
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PinEntryComponent;
